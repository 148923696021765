import {
    FETCH_REPORTS_REQUEST,
    FETCH_REPORTS_SUCCESS,
    FETCH_REPORTS_FAILURE,
    FETCH_REPORTS_BY_DAY_REQUEST,
    FETCH_REPORTS_BY_DAY_SUCCESS,
    FETCH_REPORTS_BY_DAY_FAILURE
} from "./type";

const initialState = {
    reports_loading: false,
    reports_error: false,
    reports: [],
    totalPages: 1,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORTS_REQUEST:
            return {...state, reports_loading: true, reports_error: false};
        case FETCH_REPORTS_SUCCESS:
            return {
                ...state,
                reports_loading: false,
                totalPages: action.payload.totalPages,
                reports: action.payload.reports,
                reports_error: false
            };
        case FETCH_REPORTS_FAILURE:
            return {
                ...state,
                reports_loading: false,
                reports: [],
                totalPages: 1,
                reports_error: true
            };
        case FETCH_REPORTS_BY_DAY_REQUEST:
            return {...state, reports_loading: true, reports_error: false};
        case FETCH_REPORTS_BY_DAY_SUCCESS:
            return {
                ...state,
                reports_loading: false,
                reports: action.payload.reports,
                reports_error: false
            };
        case FETCH_REPORTS_BY_DAY_FAILURE:
            return {
                ...state,
                reports_loading: false,
                reports: [],
                reports_error: true
            };
        default:
            return state;
    }
}

export default reducer;

import React, {useState} from "react";
import {makeStyles, TextField, Button, CircularProgress} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom"
import {login} from '../../../redux';
import {COLORS} from "../../../utills/constants/Colors";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
        padding: '20px',
        '& img':{
            width: '180px',
            height: '120px'
        }
    },
    form: {
        webkitBorderRadius: '10px 10px 10px 10px',
        borderRadius: '10px 10px 10px 10px',
        background: '#fff',
        padding: '30px',
        width: '90%',
        maxWidth: '450px',
        position: 'relative',
        WebkitBoxShadow: '0 30px 60px 0 rgba(0, 0, 0, 0.3)',
        boxShadow: '0 30px 60px 0 rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
        '& form > div': {
            marginBottom: '10px'
        }
    },
    error: {
        color: COLORS.ERROR,
        fontWeight: '600'
    }
}));


export default function LoginContainer() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const userState = useSelector(state => state['user']);

    const [state, setState] = useState({nickname: '', password: ''});

    if (userState.user) return <Redirect to={{pathname: '/'}}/>;

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(state));
    }

    const handleInputChange = (e) => setState({...state, [e.target.name]: e.target.value});

    return <div className={classes.root}>
        <div className={classes.form}>
            <div>
                <img src={"/img/logo.png"} id="icon" alt="logo"/>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <TextField onChange={handleInputChange} variant="outlined" type="text"
                               name="nickname" label="שם משתמש"/>
                </div>
                <div>
                    <TextField onChange={handleInputChange} variant="outlined" type="password"
                               name="password" label="סיסמא"/>
                </div>
                <div className={classes.error}>
                    {userState.error ? 'שם המשתמש או הסיסמא אינם נכונים' : null}
                </div>
                <div>
                    {userState.loading ? <CircularProgress/> :
                        <Button variant="contained" color="primary" disableElevation
                                type="submit">כניסה</Button>}
                </div>
            </form>
        </div>
    </div>
}

export const pelephoneNavigator = {
    items: [
        {title: 'דוחות', link: '/pelephone/reports'},
        {title: 'יצוא', link: '/pelephone/export'},
        {title: 'אתרים', link: '/pelephone/sites'}
    ]
};

export const engineeringNavigator = {
    items: [
        {title: 'פניות', link: '/engineering/reports', available: true, permission: 2},
        {title: 'פריטים', link: '/engineering/items', available: true, permission: 1},
        {title: 'יצוא', link: '/engineering/export', available: true, permission: 1}
    ]
};


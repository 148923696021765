import {combineReducers} from "redux";
import engineeringReportReducer from "../moduls/engineering/redux/report/reducer";
import engineeringItemsReducer from "../moduls/engineering/redux/items/reducer";
import engineeringReportsReducer from "../moduls/engineering/redux/reports/reducer";
import reducer from "../moduls/login/redux/reducer";
import usersReducer from "../moduls/users/redux/reducer";

const rootReducer = combineReducers({
    user: reducer,
    users: usersReducer,
    engineeringReport: engineeringReportReducer,
    engineeringItems: engineeringItemsReducer,
    engineeringReports: engineeringReportsReducer
});

export default rootReducer;

import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress, FormControl, FormHelperText,
    makeStyles,
    Select,
    TextField
} from "@material-ui/core";
import {isMobile} from 'react-device-detect';
import {getFromURL} from '../../../utills/Tools';
import {useDispatch, useSelector} from "react-redux";
import {COLORS} from "../../../utills/constants/Colors";
import {fetchItem, saveItem} from "../../../redux";

const useStyles = makeStyles(() => ({
    space: {
        display: isMobile ? 'grid' : 'block',
        '&>*': {
            marginRight: isMobile ? '0' : '20px',
            marginBottom: isMobile ? '20px' : '0'
        }
    },
    content: {
        marginBottom: '20px'
    },
    textarea: {
        width: isMobile ? '100%' : '50%'
    },
    footer: {
        overflowX: 'auto',
        position: 'sticky',
        width: '100%',
        bottom: '0',
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        placeContent: 'space-around',
        padding: '10px 0',
        '& button': {
            height: '40px',
            padding: isMobile ? '0 20px' : '0 40px'
        }
    }
}));
export default function EditItemComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const itemsState = useSelector(state => state['engineeringItems']);
    const [state, setState] = useState({
        id: null,
        number: '',
        description: '',
        unit: '',
        group_id: 0
    });

    useEffect(() => {
        dispatch(fetchItem(getFromURL()));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if (itemsState.item && !state.id) {
        const item = itemsState.item;

        setState({
            ...state,
            id: item.id,
            number: (item && item.number ? item.number : ''),
            description: (item && item.description ? item.description : ''),
            unit: (item && item.unit ? item.unit : ''),
            group_id: (item && item.group_id ? item.group_id : '')
        });
    }

    if (itemsState.loading) return <CircularProgress/>;

    if (itemsState.save_success) window.location.href = '/engineering/items';

    const handleInputChange = (e) => setState({...state, [e.target.name]: e.target.value});

    const handleSaveClicked = () => save(state);

    const handleDeleteClicked = () => {
        if (!window.confirm('האם למחוק את הפריט?')) return;
        state.archive = true;
        save(state);
    }

    const save = item => dispatch(saveItem(item));

    return <>
        <div>
            <a href="/engineering/items">חזור</a>
            <h3>דף פריט</h3>
            <div className={classes.content}>
                <div className={classes.space}>
                    <TextField onChange={handleInputChange} variant="outlined" type="text"
                               value={state.number}
                               name="number" label="מק&#8221;ט מחירון"/>
                    <TextField onChange={handleInputChange} variant="outlined" type="text"
                               value={state.unit}
                               name="unit" label="יחידת מידה"/>
                    <FormControl variant="outlined">
                        <Select
                            native
                            id="group_id"
                            name="group_id"
                            onChange={handleInputChange}
                            value={state.group_id}>
                            {itemsState.groups.map(group => <option
                                key={group.id} value={group.id}>{group.name}</option>)}
                        </Select>
                        <FormHelperText>קבוצה</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <h4>תיאור</h4>
                    <TextField name="description" onChange={handleInputChange}
                               className={classes.textarea}
                               multiline
                               value={state.description}
                               type="text" rows={4}
                               variant="outlined"/>
                </div>
            </div>
        </div>
        <div className={classes.footer}>
            <Button onClick={handleSaveClicked} variant="contained" color="primary">שמור</Button>
            <Button onClick={handleDeleteClicked}>מחק</Button>
        </div>
    </>
}

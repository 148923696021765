import Cookies from 'universal-cookie';
import {config} from '../config';

const cookies = new Cookies();

export const saveUser = user => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 20);
    cookies.set('lv_reports', user, {path: '/', expires: date, domain: config.url.DOMAIN});
}

export const getUser = () => cookies.get('lv_reports');


export const removeUser = () => cookies.remove('lv_reports', {
    path: '/',
    domain: config.url.DOMAIN
});

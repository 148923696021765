export const getTotalTime = (from_hour, to_hour) => {
    if (!from_hour || !to_hour) return '';
    const start = from_hour.split(":");
    const end = to_hour.split(":");
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    if (hours < 0) hours = hours + 24;
    return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
}

export const formatDateForView = date => {
    date = new Date(date);
    return formatDay(date) + '/' + formatMonth(date) + '/' + date.getFullYear();
}

const formatDate = (date, operator) => {
    return date.getFullYear() + operator + formatMonth(date) + operator + formatDay(date);
}

export const formatDateForInput = i_date => {
    if (!i_date) return formatDate(new Date(), '-');
    const date = new Date(i_date);
    return formatDate(date, '-');
}

export const getPermissionName = permission => {
    if (!permission) return 'עובד';
    switch (permission) {
        case 1:
            return 'מנהל ראשי';
        case 2:
            return 'עובד';
        default  :
            return 'עובד';
    }
}

const formatDay = date => date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
const formatMonth = date => (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);

export const getFromURL = () => window.location.href.slice(window.location.href.lastIndexOf('/') + 1, window.location.href.length);

import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    CREATE_NEW_USER_REQUEST,
    CREATE_NEW_USER_SUCCESS,
    CREATE_NEW_USER_FAILURE,
    SAVE_USER_REQUEST,
    SAVE_USER_SUCCESS,
    SAVE_USER_FAILURE,
    EMAIL_EXIST_CHECK,
    NICKNAME_EXIST_CHECK
} from "./type";
import {isEmpty} from 'lodash';
import {sendRequest, METHODE} from '../../../utills/HTTPRequests';

const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const fetchUsersSuccess = data => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: {users: data.users, totalPages: data.totalPages}
    }
}

const fetchUsersFailure = () => {
    return {
        type: FETCH_USERS_FAILURE
    }
}

const fetchUserRequest = () => {
    return {
        type: FETCH_USER_REQUEST
    }
}

const fetchUserSuccess = user => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: {user: user}
    }
}

const fetchUserFailure = () => {
    return {
        type: FETCH_USER_FAILURE
    }
}

const createNewUserRequest = () => {
    return {
        type: CREATE_NEW_USER_REQUEST
    }
}

const createNewUserSuccess = user => {
    return {
        type: CREATE_NEW_USER_SUCCESS,
        payload: {user: user}
    }
}

const createNewUserFailure = () => {
    return {
        type: CREATE_NEW_USER_FAILURE
    }
}

const saveUserRequest = () => {
    return {
        type: SAVE_USER_REQUEST
    }
}

const saveUserSuccess = () => {
    return {
        type: SAVE_USER_SUCCESS
    }
}

const saveUserFailure = () => {
    return {
        type: SAVE_USER_FAILURE
    }
}

const emailExistCheck = data => {
    return {type: EMAIL_EXIST_CHECK, payload: {isEmailExists: data}}
}

const nicknameExistCheck = data => {
    return {type: NICKNAME_EXIST_CHECK, payload: {isNicknameExists: data}}
}

export const isEmailExists = (email, userId) => {
    return (dispatch) => {
        sendRequest(METHODE.POST, '/users/check/email', {email: email, id: userId})
            .then(data => data && data.status && dispatch(emailExistCheck(data.message)));
    };
}
export const isNicknameExists = (nickname, userId) => {
    return (dispatch) => {
        sendRequest(METHODE.POST, '/users/check/nickname', {
            nickname: nickname,
            id: userId
        })
            .then(data => data && data.status && dispatch(nicknameExistCheck(data.message)));
    };
}

export const fetchUsers = (sort, page) => {
    return (dispatch) => {
        dispatch(fetchUsersRequest());
        sendRequest(METHODE.GET, '/users/' + sort + '/' + page, null)
            .then((data) => {
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(fetchUsersSuccess(data.message));
                } else {
                    dispatch(fetchUsersFailure());
                }
            })
            .catch(() => dispatch(fetchUsersFailure()));
    };
}

export const fetchUser = userId => {
    return (dispatch) => {
        dispatch(fetchUserRequest());
        sendRequest(METHODE.GET, '/users/' + userId, null)
            .then((data) => {
                console.log(data)
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(fetchUserSuccess(data.message[0]));
                } else {
                    dispatch(fetchUserFailure());
                }
            })
            .catch(() => dispatch(fetchUserFailure()));
    };
}

export const createNewUser = data => {
    return (dispatch) => {
        dispatch(createNewUserRequest());
        sendRequest(METHODE.POST, '/users/new', data)
            .then(data => {
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(createNewUserSuccess(data.message));
                } else {
                    dispatch(createNewUserFailure());
                }
            })
            .catch(() => dispatch(createNewUserFailure()));
    };
}

export const saveUser = data => {
    return (dispatch) => {
        dispatch(saveUserRequest());
        sendRequest(METHODE.POST, '/users', data)
            .then((data) => {
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(saveUserSuccess(data.message[0]));
                } else {
                    dispatch(saveUserFailure());
                }
            })
            .catch(() => dispatch(saveUserFailure()));
    };
}

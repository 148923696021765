import {
    FETCH_REPORTS_REQUEST,
    FETCH_REPORTS_SUCCESS,
    FETCH_REPORTS_FAILURE,
    FETCH_REPORTS_BY_DAY_REQUEST,
    FETCH_REPORTS_BY_DAY_SUCCESS,
    FETCH_REPORTS_BY_DAY_FAILURE
} from "./type";

import {isEmpty} from 'lodash';

import {sendRequest, METHODE} from '../../../../utills/HTTPRequests';

const fetchReportsRequest = () => {
    return {
        type: FETCH_REPORTS_REQUEST
    }
}

const fetchReportsSuccess = data => {
    return {
        type: FETCH_REPORTS_SUCCESS,
        payload: {reports: data.reports, totalPages: data.totalPages}
    }
}

const fetchReportsFailure = () => {
    return {
        type: FETCH_REPORTS_FAILURE
    }
}

const fetchReportsByDayRequest = () => {
    return {
        type: FETCH_REPORTS_BY_DAY_REQUEST
    }
}

const fetchReportsByDaySuccess = reports => {
    return {
        type: FETCH_REPORTS_BY_DAY_SUCCESS,
        payload: {reports: reports}
    }
}

const fetchReportsByDayFailure = () => {
    return {
        type: FETCH_REPORTS_BY_DAY_FAILURE
    }
}

export const fetchReports = (sort, page, query) => {
    return (dispatch) => {
        dispatch(fetchReportsRequest());
        sendRequest(METHODE.GET, '/engineering/reports/' + sort + '/' + page + '/' + query, null)
            .then((data) => {
                if (data.status && !isEmpty(data.message)) {
                    dispatch(fetchReportsSuccess(data.message));
                } else {
                    dispatch(fetchReportsFailure());
                }
            })
            .catch(() => dispatch(fetchReportsFailure()));
    };
}

export const fetchReportsByDay = day => {
    return (dispatch) => {
        dispatch(fetchReportsByDayRequest());
        sendRequest(METHODE.GET, '/engineering/reports/day/' + day, null)
            .then(data => {
                if (data.status) {
                    dispatch(fetchReportsByDaySuccess(data.message));
                } else {
                    dispatch(fetchReportsByDayFailure());
                }
            })
            .catch(() => dispatch(fetchReportsByDayFailure()));
    };
}

import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    FormControl,
    makeStyles, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {SwapVert} from "@material-ui/icons";
import {COLORS} from "../../../utills/constants/Colors";
import {Pagination} from "@material-ui/lab";
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";
import NewItemComponent from "../components/NewItemComponent";

const useStyles = makeStyles(() => ({
    top_container: {
        display: 'flex'
    },
    center_container: {
        justifyContent: 'space-between',
        margin: '20px 0',
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                marginRight: '10px'
            }
        }
    },
    bottom_container: {},
    swipe_sort: {
        cursor: 'pointer'
    },
    search: {
        width: '100%',
        height: '50px',
    },
    table_head: {
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        color: '#fff'
    },
    table_row: {
        cursor: 'pointer'
    },
    pagination: {
        direction: 'ltr'
    }
}));

export default function ItemsContainer(props) {
    const classes = useStyles();
    const engineeringItems = useSelector(state => state['engineeringItems']);
    const [state, setState] = useState({
        sort: 'number',
        items: [],
        totalPages: 0,
        query: ''
    });

    if (state.items.length !== engineeringItems.items.length || (!isEmpty(state.items) && !isEmpty(engineeringItems.items) && state.items[0].id !== engineeringItems.items[0].id)) {
        setState({
            ...state,
            totalPages: engineeringItems.totalPages,
            items: engineeringItems.items
        });
    }

    useEffect(() => {
        fetchItems();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchItems = page => props.fetchItems && props.fetchItems(state.sort, page ? page : 1, state.query);

    if (engineeringItems.new_item_created > 0) window.location.href = '/engineering/items/' + engineeringItems.new_item_created;

    if (isEmpty(state.items) && engineeringItems.loading) return <CircularProgress/>;

    const handleItemClicked = (id, _) => window.location.href = '/engineering/items/' + id;

    const handleSortChange = e => {
        const sortValue = e.target.value;
        setState({
            ...state, items: state.items.sort((a, b) => {
                if (a[sortValue] < b[sortValue]) return 1;
                if (a[sortValue] > b[sortValue]) return -1;
                return 0;
            }), sort: sortValue
        });
    }

    const handleOnSwipeSortClicked = () => setState({
        ...state,
        items: Array.from(state.items.reverse())
    });
    //
    // const handleSearch = (e) => {
    //     setState({...state, query: e.target.value});
    //     props.search && props.search(state.sort, state.page, e.target.value)
    // };

    const pageChanged = (e, value) => fetchItems(value);

    return <div>
        <h2>פריטים</h2>
        <NewItemComponent/>
        {/*<TextField*/}
        {/*    onChange={handleSearch}*/}
        {/*    className={classes.search}*/}
        {/*    variant="outlined"*/}
        {/*    InputProps={{*/}
        {/*        startAdornment: (*/}
        {/*            <InputAdornment position="start">*/}
        {/*                <Search/>*/}
        {/*            </InputAdornment>*/}
        {/*        ),*/}
        {/*    }}*/}
        {/*/>*/}
        <div className={classes.center_container}>
            <div>
                <span>סידור לפי</span>
                <FormControl variant="outlined">
                    <Select
                        native
                        onChange={handleSortChange}
                        value={state.sort}
                    >
                        <option value={'number'}>מק"ט מחירון</option>
                        <option value={'group_name'}>קבוצה</option>
                    </Select>
                </FormControl>
                <SwapVert onClick={handleOnSwipeSortClicked} className={classes.swipe_sort}/>
            </div>
            <Pagination onChange={pageChanged} className={classes.pagination}
                        count={state.totalPages}/>
        </div>
        <div className={classes.bottom_container}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.table_head}>
                        <TableRow>
                            <TableCell align="left">מק"ט מחירון</TableCell>
                            <TableCell align="left">תיאור</TableCell>
                            <TableCell align="left">יחידת מידה</TableCell>
                            <TableCell align="right">קבוצה</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isEmpty(state.items)
                            ?
                            <TableRow>
                                <TableCell align="center">אין פריטים</TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                            :
                            state.items.map((item) => (
                                <TableRow onClick={handleItemClicked.bind(this, item.id)}
                                          className={classes.table_row} hover key={item.id}>
                                    <TableCell
                                        align="left">{item.number}</TableCell>
                                    <TableCell align="left">{item.description}</TableCell>
                                    <TableCell
                                        align="left">{item.unit}</TableCell>
                                    <TableCell align="right">{item.group_name}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>;
}

import React from "react";
import {Divider, Drawer, Link, List, makeStyles, Toolbar} from "@material-ui/core";
import {COLORS} from "../utills/constants/Colors";
import {useDispatch, useSelector} from "react-redux";
import {removeUser} from "../redux";
import {isMobile} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    drawer: {
        minWidth: '150px',
        backgroundColor: COLORS.NAVIGATOR_BACKGROUND_COLOR
    },
    not_available: {
        color: COLORS.NAV_BAR_BACKGROUND_COLOR + '!important',
        '&:hover': {
            backgroundColor: COLORS.NAVIGATOR_BACKGROUND_COLOR + '!important',
            cursor: 'default!important'
        }
    },
    list: {
        '&>*:hover': {
            backgroundColor: COLORS.NAVIGATOR_HOVER_COLOR,
        },
        '& a, span': {
            color: COLORS.LINK_COLOR,
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                textDecoration: 'none',
            }
        }
    },
    current: {
        fontWeight: 500
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    logout: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    user_area: {
        '& *': {
            marginRight: isMobile ? '0' : '6px',
            color: COLORS.LINK_COLOR,
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }
}));
export default function Navigator(props) {
    const data = props.data;
    const userPermission = props.userPermission;
    const classes = useStyles();
    const dispatch = useDispatch();
    const isCurrent = path => window.location.href.includes(path) ? classes.current : null;
    const user = useSelector(state => state['user'].user);


    const handleLogout = () => {
        if (!window.confirm('האם לצאת מהמערכת?')) return;
        window.location.href = '/';
        dispatch(removeUser());
    }
    return <Drawer open={props.open} variant="persistent" classes={{paper: classes.drawer}}>
        <div className={classes.drawerHeader}>
            <Toolbar/>
        </div>
        <Divider/>
        <div className={classes.user_area}>
            <span>שלום {user.first_name + ' ' + user.last_name}</span>
        </div>
        <Divider/>
        <List className={classes.list}>
            {
                data && data.items.map((item) =>
                    item.available && item.permission >= userPermission ?
                        <Link key={item.link} href={item.link}
                              className={isCurrent(item.link)}>{item.title}</Link>
                        :
                        null
                )}
        </List>
        <Divider/>
        <div className={classes.list}>
            <span className={classes.logout} onClick={handleLogout}>יציאה</span>
        </div>
    </Drawer>
}

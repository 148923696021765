import React from "react";
import {Switch, Route} from "react-router-dom";
import EditReportComponent from '../components/EditReportComponent';
import EditItemComponent from '../components/EditItemComponent';
import ReportsContainer from "./ReportsContainer";
import {
    fetchReports,
    fetchItems
} from '../../../redux'
import {useDispatch} from "react-redux";
import ExportComponent from "../components/ExportComponent";
import ItemsContainer from "./ItemsContainer";
import {Redirect} from "react-router-dom"

export default function EngineeringContainer() {
    const dispatch = useDispatch();

    const handleFetchReports = (sort, page, query) => dispatch(fetchReports(sort, page, query));

    const handleFetchItems = (sort, page, query) => dispatch(fetchItems(sort, page, query));

    // const handleSearch = (sort,page,query) => {
    //     if (query.length < 3) return;
    //     handleFetchReports(sort,page,query)
    // }

    return <div>
        <Switch>
            <Route path="/engineering/reports/:id" component={() => <EditReportComponent
                fetchItems={handleFetchItems}
            />}/>
            <Route path="/engineering/reports/"
                   component={() => <ReportsContainer
                       fetchReports={handleFetchReports}
                       // search={handleSearch}
                   />}/>
            <Route path="/engineering/items/:id"
                   component={() => <EditItemComponent
                       fetchItems={handleFetchItems}/>}/>
            <Route path="/engineering/items/"
                   component={() => <ItemsContainer
                       fetchItems={handleFetchItems}/>}/>
            <Route path="/engineering/export/"
                   component={() => <ExportComponent
                       fetchItems={handleFetchItems}/>}/>
            <Route>
                <Redirect to={{pathname: '/engineering/reports/'}}/>
            </Route>
        </Switch>
    </div>
}

import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    CREATE_NEW_USER_REQUEST,
    CREATE_NEW_USER_SUCCESS,
    CREATE_NEW_USER_FAILURE,
    SAVE_USER_REQUEST,
    SAVE_USER_SUCCESS,
    SAVE_USER_FAILURE,
    EMAIL_EXIST_CHECK,
    NICKNAME_EXIST_CHECK
} from "./type";

const initialState = {
    loading: false,
    users: [],
    user: null,
    new_user_created: 0,
    totalPages: 1,
    error: false,
    errorMessage: '',
    redirect: false,
    isEmailExists: false,
    isNicknameExists: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                isEmailExists: false,
                isNicknameExists: false,
                new_user_created: 0,
                error: false
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                totalPages: action.payload.totalPages,
                users: action.payload.users
            };
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                redirect: false,
                users: [],
                totalPages: 1,
                error: true,
                errorMessage: 'ישנה תקלה אנא נסה שוב'
            };
        case FETCH_USER_REQUEST:
            return {...state, loading: true, user: null, new_user_created: 0, error: false};
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                user: null,
                error: true,
                errorMessage: 'ישנה תקלה אנא נסה שוב'
            };
        case CREATE_NEW_USER_REQUEST:
            return {...state, loading: true, error: false};
        case CREATE_NEW_USER_SUCCESS:
            return {...state, loading: false, new_user_created: action.payload.user.id};
        case CREATE_NEW_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: 'ישנה תקלה ביצירת משתמש חדש אנא נסה שוב'
            };
        case SAVE_USER_REQUEST:
            return {...state, loading: true, error: false};
        case SAVE_USER_SUCCESS:
            return {...state, loading: false, redirect: true};
        case SAVE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: 'ישנה תקלה בשמירת המשתמש אנא נסה שוב'
            };
        case EMAIL_EXIST_CHECK:
            return {...state, isEmailExists: action.payload.isEmailExists.length > 0}
        case NICKNAME_EXIST_CHECK:
            return {...state, isNicknameExists: action.payload.isNicknameExists.length > 0}
        default:
            return state;
    }
}

export default reducer;

import {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SET_USER, LOGOUT} from "./type";
import {isEmpty} from 'lodash';
import {sendRequest, METHODE} from '../../../utills/HTTPRequests';

const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    }
}

const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: {user: user}
    }
}

const loginFailure = () => {
    return {
        type: LOGIN_FAILURE
    }
}

export const setUser = user => {
    return {
        type: SET_USER,
        payload: {user: user}
    }
}

export const removeUser = () => {
    return {
        type: LOGOUT
    }
}

export const login = (data) => {
    return (dispatch) => {
        dispatch(loginRequest());
        sendRequest(METHODE.POST, '/login', data)
            .then(data => {
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(loginSuccess(data.message[0]));
                } else {
                    dispatch(loginFailure());
                }
            })
            .catch(() => dispatch(loginFailure()));
    };
}

import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    makeStyles,
    Paper, Table, TableBody, TableCell, Select,
    TableContainer, TableHead, TableRow, FormControl,
    TextField, MenuItem, InputLabel
} from "@material-ui/core";
import {isMobile} from 'react-device-detect';
import {formatDateForInput, getFromURL} from '../../../utills/Tools';
import {useDispatch, useSelector} from "react-redux";
import {COLORS} from "../../../utills/constants/Colors";
import {isEmpty} from 'lodash';
import AddItemComponent from './AddItemComponent';
import {fetchReport, saveReport} from "../../../redux";
import ExportReportToExcel from "./ExportReportToExcel";

const useStyles = makeStyles(() => ({
    space: {
        display: isMobile ? 'grid' : 'block',
        '&>*': {
            marginRight: isMobile ? '0' : '20px',
            marginBottom: isMobile ? '20px' : '0'
        }
    },
    content: {
        marginBottom: '20px'
    },
    textarea: {
        width: isMobile ? '100%' : '50%'
    },
    footer: {
        overflowX: 'auto',
        position: 'sticky',
        width: '100%',
        bottom: '0',
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        placeContent: 'space-around',
        padding: '10px 0',
        '& button': {
            height: '40px',
            padding: isMobile ? '0 20px' : '0 40px'
        }
    },
    add_employee_container: {
        display: 'flex'
    },
    employees_container: {
        marginBottom: '10px'
    },
    employee: {
        '& >span': {
            padding: '5px 10px',
            border: '1px solid ' + COLORS.NAV_BAR_BACKGROUND_COLOR,
            borderRadius: '4px'
        }
    },
    add_item_button: {
        marginBottom: '20px'
    },
    table: {
        width: isMobile ? '100%' : '50%'
    },
    table_head: {
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        color: '#fff'
    },
    unit_input: {
        width: '60px',
        '& input': {
            height: '5px',
        }
    },
    status_text: {
        boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        padding: '0 10px',
        backgroundColor: COLORS.STATUS_BACKGROUND,
    },
    hidden: {
        visibility: 'hidden'
    },
    regular: {}
}));
export default function EditReportComponent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const reportState = useSelector(state => state['engineeringReport']);
    const [employee, setEmployee] = useState('');
    const [itemsDialogOpen, setItemsDialogOpen] = useState(false);
    const [state, setState] = useState({
        id: null,
        number: '',
        project_number: '',
        from_hour: null,
        to_hour: null,
        date: formatDateForInput(),
        description: '',
        details: '',
        projects: [],
        project: 'none',
        employees: [],
        items: [],
        status_text: 'בעריכה'
    });

    useEffect(() => {
        dispatch(fetchReport(getFromURL()));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if (reportState.report && !state.id) {
        const report = reportState.report;
        setState({
            ...state,
            id: report.id,
            number: (report && report.data && report.data.number ? report.data.number : ''),
            status: (report && report.data && report.data.status ? report.data.status : 1),
            status_text: (report && report.data ? report.data.status_text : 'בעריכה'),
            project: (report && report.data && report.data.project ? report.data.project : 'none'),
            project_number: (report && report.data && report.data.project_number ? report.data.project_number : ''),
            from_hour: (report && report.data ? report.data.from_hour : ''),
            to_hour: (report && report.data ? report.data.to_hour : ''),
            date: formatDateForInput(report && report.data && report.data.date ? report.data.date : null),
            description: (report && report.data && report.data.description ? report.data.description : ''),
            details: (report && report.data && report.data.details ? report.data.details : ''),
            employees: (report && report.employees ? report.employees : []),
            items: (report && report.items ? report.items : []),
            projects: (report && report.projects ? report.projects : [])
        });
    }

    if (reportState.report_loading) return <CircularProgress/>;

    if (reportState.redirect || reportState.save_success) window.location.href = '/engineering/reports';

    const handleInputChange = (e) => setState({...state, [e.target.name]: e.target.value});

    const handleEmployeeName = e => setEmployee(e.target.value);

    const handleAddEmployeeClicked = () => {
        if (isEmpty(employee)) return;
        const employees = state.employees;
        employees.push({name: employee});
        setState({...state, employees: employees});
        setEmployee('');
    }

    const handleRemoveEmployee = (name, _) => setState({
        ...state,
        employees: state.employees.filter((employee) => employee.name !== name)
    });

    const handleAddItemClicked = () => setItemsDialogOpen(true);

    const handleCloseAddItem = () => setItemsDialogOpen(false);

    const handleItemSelected = item => {
        const items = state.items;
        item.count = 1;
        items.push(item);
        setState({...state, items: items});
        setItemsDialogOpen(false);
    }

    const handleDeleteItem = (item, _) => setState({
        ...state,
        items: state.items.filter((i_item) => i_item !== item)
    });

    const handleSendClicked = () => {
        state.statusAction = state.status === 1 ? 'sent' : 'approved';
        state.send_date = formatDateForInput();
        state.changeStatus = true;
        save(state);
    }

    const handleSaveClicked = () => save(state);

    const handleDeleteClicked = () => {
        if (!window.confirm('האם למחוק את הפניה?')) return;
        state.archive = true;
        state.changeStatus = true;
        save(state);
    }

    const handleCountChanged = (itemId, e) => {
        const items = state.items;
        items.map(item => {
            if (Number(item.id) === Number(itemId)) item.count = e.target.value;
            return item;
        });
        setState({...state, items: items});
    }

    const save = (report) => {
        dispatch(saveReport(report));
    }

    const sendButtonText = () => state.status === 1 ? 'שלח' : state.status === 2 ? 'טופל' : '';

    return <>
        <div>
            <a href="/engineering/reports">חזור</a>
            <h3>דף פנייה - <span className={classes.status_text}>{state.status_text}</span></h3>
            <div className={classes.content}>
                <ExportReportToExcel report={state}/>
                <div className={classes.space}>
                    <TextField onChange={handleInputChange} variant="outlined" type="number"
                               value={state.number}
                               name="number" label="מספר פנייה"/>
                    <TextField onChange={handleInputChange} value={state.date}
                               variant="outlined" label="תאריך" type="date"
                               name="date"/>
                    <TextField onChange={handleInputChange} variant="outlined" type="number"
                               value={state.project_number}
                               name="project_number" label="מספר פרוייקט ברמדור"/>
                    <FormControl variant="outlined">
                        <InputLabel>פרוייקט</InputLabel>
                        <Select name="project" label="פרוייקט" onChange={handleInputChange}
                                value={state.project}>
                            <MenuItem value="none">בחר פרוייקט</MenuItem>
                            {state.projects.map(project => <MenuItem
                                value={project.name}>{project.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <h4>שמות העובדים</h4>
                    <div className={classes.employees_container}>
                        {state.employees ? state.employees.map((employee, index) =>
                            <div key={index} className={classes.employee}>
                                <span>{employee.name}</span><Button
                                onClick={handleRemoveEmployee.bind(this, employee.name)}>X</Button>
                            </div>) : null}
                    </div>
                    <div className={[classes.space, classes.add_employee_container].join(' ')}>
                        <TextField onChange={handleEmployeeName} variant="outlined" type="text"
                                   name="employee_name" value={employee} label="שם העובד"/>
                        <Button onClick={handleAddEmployeeClicked} variant="contained"
                                color="primary">הוסף עובד</Button>
                    </div>
                </div>
                <div>
                    <h4>זמן ביצוע (שעות ודקות)</h4>
                    <div className={classes.space}>
                        <span>משעה</span>
                        <TextField name="from_hour" onChange={handleInputChange} type="time"
                                   value={state.from_hour}/>
                        <span>עד שעה</span>
                        <TextField name="to_hour" onChange={handleInputChange} type="time"
                                   value={state.to_hour}/>
                    </div>
                </div>
                <div>
                    <h4>תיאור העבודה בקצרה</h4>
                    <TextField name="description" onChange={handleInputChange}
                               className={classes.textarea}
                               multiline
                               value={state.description}
                               type="text" rows={4}
                               variant="outlined"/>
                </div>
                <div>
                    <h4>פריטים</h4>
                    <Button className={classes.add_item_button} onClick={handleAddItemClicked}
                            variant="contained" color="primary">הוסף
                        פריט</Button>
                    <div>
                        {isEmpty(state.items) ? null :
                            <TableContainer component={Paper} className={classes.table}>
                                <Table>
                                    <TableHead className={classes.table_head}>
                                        <TableRow>
                                            <TableCell align="left">מק"ט מחירון</TableCell>
                                            <TableCell align="left">תיאור</TableCell>
                                            <TableCell align="left">יחידת מידה</TableCell>
                                            <TableCell align="left">כמות</TableCell>
                                            <TableCell align="left"/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.items.map((item) => (
                                            <TableRow className={classes.table_row} hover
                                                      key={item.id}>
                                                <TableCell align="left">{item.number}</TableCell>
                                                <TableCell
                                                    align="left">{item.description}</TableCell>
                                                <TableCell align="left">{item.unit}</TableCell>
                                                <TableCell align="left">
                                                    <TextField className={classes.unit_input}
                                                               onChange={handleCountChanged.bind(this, item.id)}
                                                               defaultValue={item.count}
                                                               variant="outlined" type="number"/>
                                                </TableCell>
                                                <TableCell
                                                    align="right"><Button
                                                    onClick={handleDeleteItem.bind(this, item)}>הסר</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                    </div>
                </div>
                <div>
                    <h4>פריטים נוספים ועבודות נוספות</h4>
                    <TextField name="details" onChange={handleInputChange}
                               className={classes.textarea} multiline
                               value={state.details}
                               type="text" rows={4}
                               variant="outlined"/>
                </div>
            </div>
        </div>
        <div className={classes.footer}>
            <Button onClick={handleSendClicked} variant="contained"
                    className={state.status === 3 ? classes.hidden : classes.regular}
                    color="secondary">{sendButtonText()}</Button>
            <Button onClick={handleSaveClicked} variant="contained" color="primary">שמור</Button>
            <Button onClick={handleDeleteClicked}>מחק</Button>
        </div>
        <AddItemComponent open={itemsDialogOpen} close={handleCloseAddItem}
                          fetchItems={props.fetchItems} itemSelected={handleItemSelected}/>
    </>
}

import React from "react";
import './App.css';
import {Provider} from 'react-redux';
import store from "./redux/store";
import {
    BrowserRouter as Router
} from "react-router-dom";
import MainContainer from "./containers/MainContainer";

import rtl from "jss-rtl";
import {create} from 'jss';
import {StylesProvider, ThemeProvider, createMuiTheme, jssPreset} from "@material-ui/core/styles";

function App() {
    const jss = create({plugins: [...jssPreset().plugins, rtl()]});
    const rtlTheme = createMuiTheme({direction: "rtl"});

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={rtlTheme}>
                <div className="App">
                    <Provider store={store}>
                        <Router>
                            <MainContainer/>
                        </Router>
                    </Provider>
                </div>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default App;

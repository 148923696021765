const prod = {
    url: {
        API_URL: 'https://lvserver.lironvaizer.com/data',
        DOMAIN: '.lironvaizer.com'
    }
};
const dev = {
    url: {
        API_URL: 'http://localhost:8080/data',
        DOMAIN: 'localhost'
    }
};

const stage = {
    url: {
        API_URL: 'https://stagelvserver.lironvaizer.com',
        DOMAIN: 'lv.lironvaizer.com'
    }
};

export const config = (process.env.REACT_APP_ENV === 'dev' ? dev : process.env.REACT_APP_ENV === 'stage' ? stage : prod);

import React from 'react';
import {Switch, Route} from "react-router-dom";
import UsersContainer from "./UsersContainer";
import UserComponent from '../components/UserComponent';

export default function ManageUsersContainer() {
    return <div>
        <Switch>
            <Route path="/users/:id" component={() => <UserComponent/>}/>
            <Route path="/users" component={() => <UsersContainer/>}/>
        </Switch>
    </div>;
}

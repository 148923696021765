import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {createNewItem} from "../../../redux";

const useStyles = makeStyles(() => ({
    new_item_button: {
        width: '200px',
        marginLeft: '20px'
    }
}));
export default function NewItemComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const engineeringItems = useSelector(state => state['engineeringItems']);

    const handleNewItemClicked = () => dispatch(createNewItem());

    if (engineeringItems.new_item_created > 0) window.location.href = '/engineering/items/' + engineeringItems.new_item_created;

    return <Button className={classes.new_item_button} onClick={handleNewItemClicked}
                   variant="contained" color="primary">פריט חדש</Button>
}

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';
export const FETCH_ITEM_REQUEST = 'FETCH_ITEM_REQUEST';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAILURE = 'FETCH_ITEM_FAILURE';
export const SAVE_ITEM_REQUEST = 'SAVE_ITEM_REQUEST';
export const SAVE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
export const SAVE_ITEM_FAILURE = 'SAVE_ITEM_FAILURE';
export const CREATE_NEW_ITEM_REQUEST = 'CREATE_NEW_ITEM_REQUEST';
export const CREATE_NEW_ITEM_SUCCESS = 'CREATE_NEW_ITEM_SUCCESS';
export const CREATE_NEW_ITEM_FAILURE = 'CREATE_NEW_ITEM_FAILURE';

import {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SET_USER, LOGOUT} from "./type";
import {saveUser, removeUser, getUser} from "../../../utills/Cookies";

const initialState = {
    loading: false,
    user: getUser(),
    error: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {...state, loading: true, error: false};
        case LOGIN_SUCCESS:
            saveUser(action.payload.user);
            return {...state, loading: false, user: action.payload.user, error: false};
        case LOGIN_FAILURE:
            return {...state, loading: false, user: null, error: true};
        case SET_USER:
            return {...state, user: action.payload.user};
        case LOGOUT: {
            removeUser();
            return {...state, user: null};
        }
        default:
            return state;
    }
}

export default reducer;

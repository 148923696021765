import {
    FETCH_REPORT_REQUEST,
    FETCH_REPORT_SUCCESS,
    FETCH_REPORT_FAILURE,
    SAVE_REPORT_REQUEST,
    SAVE_REPORT_SUCCESS,
    SAVE_REPORT_FAILURE,
    CREATE_NEW_REPORT_REQUEST,
    CREATE_NEW_REPORT_SUCCESS,
    CREATE_NEW_REPORT_FAILURE,
} from "./type";

const initialState = {
    new_report_error: false,
    new_report_created: 0,
    report_loading: true,
    report_error: false,
    save_success: false,
    save_error: false,
    report: null,
    redirect: false,
    save_message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORT_REQUEST:
            return {
                ...state,
                report_loading: true,
                report_error: false,
                new_report_created: 0,
                redirect: false
            };
        case FETCH_REPORT_SUCCESS:
            return {
                ...state,
                report_loading: false,
                report: action.payload.report
            };
        case FETCH_REPORT_FAILURE:
            return {
                ...state,
                report_loading: false,
                report_error: true,
            };
        case SAVE_REPORT_REQUEST:
            return {
                ...state,
                report_loading: true,
                save_success: false,
                save_error: false,
                save_message: null
            };
        case SAVE_REPORT_SUCCESS:
            return {
                ...state,
                report_loading: false,
                save_success: true,
                redirect: action.payload.changeStatus,
                save_message: 'הפנייה נשמרה בהצלחה'
            };
        case SAVE_REPORT_FAILURE:
            return {
                ...state,
                report_loading: false,
                save_error: true,
                save_message: 'שגיאה בשמירת הפנייה'
            };
        case CREATE_NEW_REPORT_REQUEST:
            return {
                ...state,
                new_report_error: false,
            };
        case CREATE_NEW_REPORT_SUCCESS:
            return {
                ...state,
                new_report_error: false,
                new_report_created: action.payload.report.id
            };
        case CREATE_NEW_REPORT_FAILURE:
            return {
                ...state,
                new_report_error: true,
                report: null
            };
        default:
            return state;
    }
}

export default reducer;

import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Header from "../components/Header";
import LoginContainer from "../moduls/login/containers/LoginContainer";
import PelephoneContainer from "../moduls/pelephone/containers/PelephoneContainer";
import EngineeringContainer from "../moduls/engineering/containers/EngineeringContainer";
import ManageUsersContainer from "../moduls/users/containers/ManageUsersContainer";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core";
import Navigator from "../components/Navigator";
import {engineeringNavigator} from '../utills/constants/NavigatorsData';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '20px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content_margin: {
        marginLeft: '150px',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
}));

export default function MainContainer() {
    const user = useSelector(state => state['user']);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpenClicked = () => setOpen(!open);

    const getNavigatorDataByLocation = () => window.location.href.includes('engineering') ? engineeringNavigator : null;
    return <>
        {user.user ?
            <>
                <Header drawerClicked={handleDrawerOpenClicked}/>
                <div className={classes.drawerHeader}/>
                <div className={[classes.content, open ? classes.content_margin : null].join(' ')}>
                    <Switch>
                        <Route path="/pelephone" component={PelephoneContainer}/>
                        <Route path="/engineering" component={EngineeringContainer}/>
                        <Route path="/users" component={ManageUsersContainer}/>
                        <Route>
                            <Redirect to={{pathname: '/engineering/'}}/>
                        </Route>
                    </Switch>
                </div>
                <Navigator open={open} userPermission={user.user.permission}
                           data={getNavigatorDataByLocation()}/>
            </> :
            <LoginContainer/>
        }
    </>
}

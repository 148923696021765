import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, makeStyles, TextField} from "@material-ui/core";
import {formatDateForInput} from "../../../utills/Tools";
import {useDispatch, useSelector} from "react-redux";
import {fetchReportsByDay} from "../../../redux";
import ExportReportToExcel from "./ExportReportToExcel";

const useStyles = makeStyles(() => ({
    item_container: {
        display: 'flex',
        "&>*": {
            marginRight: '20px',
            display: 'flex'
        }
    }
}));

export default function ExportComponent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const reportsState = useSelector(state => state['engineeringReports']);
    const [date, setDate] = useState(formatDateForInput());

    useEffect(() => {
        props.fetchItems && props.fetchItems();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleDateChange = e => setDate(e.target.value);

    const exportToExcelDay = () => dispatch(fetchReportsByDay(date));

    return <div>
        {reportsState.loading ? <CircularProgress/> : null}
        <h3>יצוא פניות</h3>
        <h4>יצוא פניות לפי יום</h4>
        <div className={classes.item_container}>
            <TextField onChange={handleDateChange} value={date}
                       variant="outlined" label="בחר תאריך" type="date" name="date"/>
            <Button variant='contained' onClick={exportToExcelDay} color="primary">יצוא</Button>
            {reportsState.reports.length > 0 ?
                <ExportReportToExcel reports={reportsState.reports}/> : null}
        </div>
    </div>
}

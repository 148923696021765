import {
    FETCH_REPORT_REQUEST,
    FETCH_REPORT_SUCCESS,
    FETCH_REPORT_FAILURE,
    SAVE_REPORT_REQUEST,
    SAVE_REPORT_SUCCESS,
    SAVE_REPORT_FAILURE,
    CREATE_NEW_REPORT_REQUEST,
    CREATE_NEW_REPORT_SUCCESS,
    CREATE_NEW_REPORT_FAILURE
} from "./type";

import {isEmpty} from 'lodash';

import {sendRequest, METHODE} from '../../../../utills/HTTPRequests';

const fetchReportRequest = () => {
    return {
        type: FETCH_REPORT_REQUEST
    }
}

const fetchReportSuccess = report => {
    return {
        type: FETCH_REPORT_SUCCESS,
        payload: {report: report}
    }
}

const fetchReportFailure = () => {
    return {
        type: FETCH_REPORT_FAILURE
    }
}

const saveReportRequest = () => {
    return {
        type: SAVE_REPORT_REQUEST
    }
}

const saveReportSuccess = changeStatus => {
    return {
        type: SAVE_REPORT_SUCCESS,
        payload: {changeStatus: changeStatus}
    }
}

const saveReportFailure = () => {
    return {
        type: SAVE_REPORT_FAILURE
    }
}

const createNewReportRequest = () => {
    return {
        type: CREATE_NEW_REPORT_REQUEST
    }
}

const createNewReportSuccess = report => {
    return {
        type: CREATE_NEW_REPORT_SUCCESS,
        payload: {report: report}
    }
}

const createNewReportFailure = () => {
    return {
        type: CREATE_NEW_REPORT_FAILURE
    }
}

export const fetchReport = id => {
    return (dispatch) => {
        dispatch(fetchReportRequest());
        sendRequest(METHODE.GET, '/engineering/report/' + id, null)
            .then((data) => {
                if (data.status && !isEmpty(data.message)) {
                    dispatch(fetchReportSuccess(data.message));
                } else {
                    dispatch(fetchReportFailure());
                }
            })
            .catch(() => dispatch(fetchReportFailure()));
    };
}

export const saveReport = report => {
    return (dispatch) => {
        dispatch(saveReportRequest());
        sendRequest(METHODE.POST, '/engineering/report', report)
            .then((data) => {
                if (data.status) {
                    dispatch(saveReportSuccess(report.changeStatus));
                } else {
                    dispatch(saveReportFailure());
                }
            })
            .catch(() => dispatch(saveReportFailure()));
    };
}

export const createNewReport = () => {
    return (dispatch) => {
        dispatch(createNewReportRequest());
        sendRequest(METHODE.POST, '/engineering/report/new', null)
            .then((data) => {
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(createNewReportSuccess(data.message));
                } else {
                    dispatch(createNewReportFailure());
                }
            })
            .catch(() => dispatch(createNewReportFailure()));
    };
}

import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {createNewReport} from "../../../redux";

const useStyles = makeStyles(() => ({
    new_report_button: {
        width: '200px',
        marginLeft: '20px'
    }
}));
export default function NewReportComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const engineeringReport = useSelector(state => state['engineeringReport']);

    const handleNewReportClicked = () => dispatch(createNewReport());

    if (engineeringReport.new_report_created > 0) window.location.href = '/engineering/reports/' + engineeringReport.new_report_created;

    return <Button className={classes.new_report_button} onClick={handleNewReportClicked}
                   variant="contained" color="primary">פנייה
        חדשה</Button>
}

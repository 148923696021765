import React, {useEffect} from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, IconButton,
    makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import {Close} from "@material-ui/icons";
import {isEmpty} from 'lodash';
import {COLORS} from "../../../utills/constants/Colors";
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            minWidth: '30%'
        },
        '& tbody tr:hover': {
            cursor: 'pointer'
        }
    },
    header: {
        '& h2': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    table_head: {
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        color: '#fff'
    }
}));

export default function AddItemComponent(props) {
    const classes = useStyles();
    const itemsState = useSelector(state => state['engineeringItems']);

    useEffect(() => {
        props.fetchItems && props.fetchItems();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleItemClicked = (item, _) => props.itemSelected && props.itemSelected(item);

    return <Dialog className={classes.dialog} onClose={props.close} open={props.open}>
        <DialogTitle className={classes.header} onClose={props.close}>
            הוספת פריט
            <IconButton onClick={props.close}>
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            {itemsState.loading ? <CircularProgress/> :
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead className={classes.table_head}>
                            <TableRow>
                                <TableCell align="left">מק"ט מחירון</TableCell>
                                <TableCell align="left">תיאור</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isEmpty(itemsState.items)
                                ?
                                <TableRow>
                                    <TableCell align="center">אין פריטים</TableCell>
                                    <TableCell/>
                                </TableRow>
                                :
                                itemsState.items.map((item) => (
                                    <TableRow onClick={handleItemClicked.bind(this, item)}
                                              className={classes.table_row} hover key={item.id}>
                                        <TableCell align="left">{item.number}</TableCell>
                                        <TableCell align="left">{item.description}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </DialogContent>
    </Dialog>
}

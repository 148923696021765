import React from "react";
import {
    makeStyles,
    AppBar,
    Toolbar,
    Link,
    IconButton
} from '@material-ui/core';
import {COLORS} from '../utills/constants/Colors';
import {logo, items} from '../utills/constants/HeaderData';
import {Menu} from "@material-ui/icons";
import {isMobile} from 'react-device-detect';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {

        '& .MuiAppBar-colorPrimary': {
            backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        }

    },
    appBar: {
        overflowX: 'auto',
        zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
        width: '50px',
        height: '30px',
        marginLeft: isMobile ? '0' : '20px'
    },
    container: {
        justifyContent: 'space-between',
        "&>div": {
            display: 'flex',
            alignItems: 'center'
        },

    },
    links: {
        '& a, span': {
            marginLeft: '20px',
            color: COLORS.LINK_COLOR,
        },
        '& span:first-child': {
            marginLeft: '0',
        }
    },
    current: {
        fontWeight: 500
    },
    not_available: {
        color: COLORS.NOT_AVAILABLE + '!important'
    }
}));
export default function Header(props) {
    const classes = useStyles();
    const user = useSelector(state => state['user'].user);
    const isCurrent = path => window.location.href.includes(path) ? classes.current : null;

    const getMenuItem = item => <Link key={item.link} href={item.link}
                                      className={isCurrent(item.link)}>{item.title}</Link>;

    const getNotAvailableMenuItem = item => <span key={item.link}
                                                  className={classes.not_available}>  {item.title}</span>;

    return <div className={classes.root}>
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.container}>
                <div className={classes.links}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={props.drawerClicked}>
                        <Menu/>
                    </IconButton>
                    <Link href="/">
                        <img alt="logo" src={logo} className={classes.logo}/>
                    </Link>
                    {
                        items.map(item =>
                            item.available ?
                                item.minPermission ?
                                    user.permission <= item.minPermission ?
                                        getMenuItem(item) :
                                        getNotAvailableMenuItem(item) :
                                    getMenuItem(item)
                                :
                                null
                        )
                    }
                </div>
            </Toolbar>
        </AppBar>

    </div>;
}

import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    FormControl,
    makeStyles, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {SwapVert} from "@material-ui/icons";
import {COLORS} from "../../../utills/constants/Colors";
import {Pagination} from "@material-ui/lab";
import {isEmpty} from 'lodash';
import {useSelector} from "react-redux";
import NewReportComponent from "../components/NewReportComponent";
import {formatDateForView} from "../../../utills/Tools";

const useStyles = makeStyles(() => ({
    top_container: {
        display: 'flex'
    },
    center_container: {
        justifyContent: 'space-between',
        margin: '20px 0',
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                marginRight: '10px'
            }
        }
    },
    bottom_container: {},
    swipe_sort: {
        cursor: 'pointer'
    },
    search: {
        width: '100%',
        height: '50px',
    },
    table_head: {
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        color: '#fff'
    },
    table_row: {
        cursor: 'pointer'
    },
    pagination: {
        direction: 'ltr'
    }
}));

export default function ReportsContainer(props) {
    const classes = useStyles();
    const engineeringReports = useSelector(state => state['engineeringReports']);
    const [state, setState] = useState({
        sort: 'date',
        reports: [],
        totalPages: 1,
        query: ''
    });
    if (state.reports.length !== engineeringReports.reports.length|| (!isEmpty(state.reports) && !isEmpty(engineeringReports.reports) && state.reports[0].id !== engineeringReports.reports[0].id)) {
        setState({
            ...state,
            totalPages: engineeringReports.totalPages,
            reports: engineeringReports.reports
        });
    }

    useEffect(() => {
        fetchReports();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchReports = page => props.fetchReports && props.fetchReports(state.sort, page ? page : 1, state.query);

    if (engineeringReports.new_report_created > 0) window.location.href = '/engineering/reports/' + engineeringReports.new_report_created;

    if (engineeringReports.loading) return <CircularProgress/>;

    const handleReportClicked = (id, _) => window.location.href = '/engineering/reports/' + id;

    const handleSortChange = e => {
        const sortValue = e.target.value;
        setState({
            ...state, reports: state.reports.sort((a, b) => {
                if (a[sortValue] < b[sortValue]) return 1;
                if (a[sortValue] > b[sortValue]) return -1;
                return 0;
            }), sort: sortValue
        });
    }

    const handleOnSwipeSortClicked = () => setState({
        ...state,
        reports: Array.from(state.reports.reverse())
    });
    //
    // const handleSearch = (e) => {
    //     setState({...state, query: e.target.value});
    //     props.search && props.search(state.sort, state.page, e.target.value)
    // };

    const pageChanged = (e, value) => fetchReports(value);

    return <div>
        <h2>פניות</h2>
        <div className={classes.top_container}>
            <NewReportComponent/>
            {/*<TextField*/}
            {/*    onChange={handleSearch}*/}
            {/*    className={classes.search}*/}
            {/*    variant="outlined"*/}
            {/*    InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*            <InputAdornment position="start">*/}
            {/*                <Search/>*/}
            {/*            </InputAdornment>*/}
            {/*        ),*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
        <div className={classes.center_container}>
            <div>
                <span>סידור לפי</span>
                <FormControl variant="outlined">
                    <Select
                        native
                        onChange={handleSortChange}
                        value={state.sort}
                    >
                        <option value={'date'}>תאריך</option>
                        <option value={'number'}>פנייה</option>
                        <option value={'project'}>פרוייקט</option>
                    </Select>
                </FormControl>
                <SwapVert onClick={handleOnSwipeSortClicked} className={classes.swipe_sort}/>
            </div>
            <Pagination onChange={pageChanged} className={classes.pagination}
                        count={state.totalPages}/>
        </div>
        <div className={classes.bottom_container}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.table_head}>
                        <TableRow>
                            <TableCell align="left">מזהה</TableCell>
                            <TableCell align="left">תאריך</TableCell>
                            <TableCell align="left">מספר פנייה</TableCell>
                            <TableCell align="left">פרוייקט</TableCell>
                            <TableCell align="left">מספר פרוייקט ברמדור</TableCell>
                            <TableCell align="right">סטטוס</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isEmpty(state.reports)
                            ?
                            <TableRow>
                                <TableCell align="center">אין פניות</TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                            :
                            state.reports.map((report) => (
                                <TableRow onClick={handleReportClicked.bind(this, report.id)}
                                          className={classes.table_row} hover key={report.id}>
                                    <TableCell
                                        align="left">{report.id}</TableCell>
                                    <TableCell
                                        align="left">{formatDateForView(new Date(report.date))}</TableCell>
                                    <TableCell align="left">{report.number}</TableCell>
                                    <TableCell align="left">{report.project}</TableCell>
                                    <TableCell
                                        align="left">{report.project_number}</TableCell>

                                    <TableCell align="right">{report.status_text}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>;
}

import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress, FormControl, InputLabel,
    makeStyles,
    Select,
    TextField
} from "@material-ui/core";
import {isMobile} from 'react-device-detect';
import {getFromURL} from '../../../utills/Tools';
import {useDispatch, useSelector} from "react-redux";
import {COLORS} from "../../../utills/constants/Colors";
import {fetchUser, isEmailExists, isNicknameExists, saveUser} from "../../../redux";

const useStyles = makeStyles(() => ({
    content: {
        marginBottom: '20px',
        display: isMobile ? 'grid' : 'block',
        '&>*': {
            marginRight: isMobile ? '0' : '20px',
            marginBottom: isMobile ? '20px' : '0'
        }
    },
    footer: {
        overflowX: 'auto',
        position: 'sticky',
        width: '100%',
        bottom: '0',
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        placeContent: 'space-around',
        padding: '10px 0',
        '& button': {
            height: '40px',
            padding: isMobile ? '0 20px' : '0 40px'
        }
    },
    table_head: {
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        color: '#fff'
    }
}));

export default function UserComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const usersState = useSelector(state => state['users']);
    const [state, setState] = useState({
        id: null,
        nickname: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        permission: 0
    });

    useEffect(() => {
        dispatch(fetchUser(getFromURL()));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if (usersState.user && !state.id) {
        const user = usersState.user;
        setState({
            ...state,
            id: user.id,
            nickname: (user.nickname ? user.nickname : ''),
            first_name: (user.first_name ? user.first_name : ''),
            last_name: (user.last_name ? user.last_name : ''),
            email: (user.email ? user.email : ''),
            permission: (user.permission ? user.permission : 0),
        });
    }

    if (usersState.loading) return <CircularProgress/>;

    if (usersState.redirect) window.location.href = '/users';

    const handleInputChange = (e) => setState({...state, [e.target.name]: e.target.value});

    const handleNicknameChange = e => {
        dispatch(isNicknameExists(e.target.value, usersState.user.id));
        handleInputChange(e);
    }

    const handleEmailChange = e => {
        dispatch(isEmailExists(e.target.value, usersState.user.id));
        handleInputChange(e);
    }

    const handleSaveClicked = () => save(state);

    const handleDeleteClicked = () => {
        if (!window.confirm('האם למחוק את המשתמש?')) return;
        state.archive = true;
        save(state);
    }

    const save = user => {
        if (usersState.isEmailExists || usersState.isNicknameExists) return;
        dispatch(saveUser(user));
    }

    return <>
        <div>
            <h3>עריכת משתמש</h3>
            <div className={classes.content}>
                <TextField error={usersState.isNicknameExists}
                           helperText={usersState.isNicknameExists ? 'שם המשתמש כבר קיים' : ''}
                           onChange={handleNicknameChange} variant="outlined" type="text"
                           value={state.nickname}
                           name="nickname" label="שם משתמש"/>
                <TextField onChange={handleInputChange} variant="outlined" type="text"
                           value={state.first_name}
                           name="first_name" label="שם פרטי"/>
                <TextField onChange={handleInputChange} variant="outlined" type="text"
                           value={state.last_name}
                           name="last_name" label="שם משפחה"/>
                <TextField error={usersState.isEmailExists}
                           helperText={usersState.isEmailExists ? 'כתובת הדוא"ל כבר קיימת' : ''}
                           onChange={handleEmailChange} variant="outlined" type="email"
                           value={state.email}
                           name="email" label="דוא&quot;ל"/>
                <TextField onChange={handleInputChange} variant="outlined" type="password"
                           value={state.password}
                           name="password" label="סיסמה"/>
                <FormControl variant="outlined">
                    <InputLabel>הרשאה</InputLabel>
                    <Select
                        native
                        value={state.permission}
                        name="permission"
                        onChange={handleInputChange}
                        label="הרשאה">
                        <option value={1}>מנהל</option>
                        <option value={2}>עובד</option>
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className={classes.footer}>
            <Button onClick={handleSaveClicked} variant="contained" color="primary">שמור</Button>
            <Button onClick={handleDeleteClicked}>מחק</Button>
        </div>
    </>
}

import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {createNewUser} from "../../../redux";

const useStyles = makeStyles(() => ({
    new_user_button: {
        width: '200px',
        marginLeft: '20px'
    }
}));
export default function NewUserComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const engineeringReport = useSelector(state => state['engineeringReport']);

    const handleNewUserClicked = () => dispatch(createNewUser());

    if (engineeringReport.new_report_created > 0) window.location.href = '/users/' + engineeringReport.new_report_created;

    return <Button className={classes.new_user_button} onClick={handleNewUserClicked}
                   variant="contained" color="primary">הוספת משתמש חדש</Button>
}

import React from "react";
import {Switch, Route} from "react-router-dom";
import PelephoneNavigator from "../components/PelephoneNavigator";

export default function PelephoneContainer() {
    return <div>
        PelephoneContainer
        <PelephoneNavigator/>
        <Switch>
            <Route path="/pelephone/reports">
                <div>reports</div>
            </Route>
            <Route>
                <div>pelephone</div>
            </Route>
        </Switch>
    </div>
}

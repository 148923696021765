import {
    FETCH_ITEMS_REQUEST,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEM_REQUEST,
    FETCH_ITEM_SUCCESS,
    FETCH_ITEM_FAILURE,
    SAVE_ITEM_REQUEST,
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,
    CREATE_NEW_ITEM_REQUEST,
    CREATE_NEW_ITEM_SUCCESS,
    CREATE_NEW_ITEM_FAILURE
} from "./type";

const initialState = {
    items: [],
    item: null,
    groups: [],
    loading: false,
    new_item_created: 0,
    new_item_loading: false,
    new_item_error: false,
    item_loading: true,
    item_error: false,
    save_success: false,
    totalPages: 1
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ITEMS_REQUEST:
            return {...state, loading: true};
        case FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                totalPages: action.payload.totalPages,
                items: action.payload.items,
                groups: action.payload.groups,
                loading: false
            };
        case FETCH_ITEMS_FAILURE:
            return {...state, items: [], loading: false};
        case FETCH_ITEM_REQUEST:
            return {...state, item_loading: true, item_error: false, new_item_created: 0};
        case FETCH_ITEM_SUCCESS:
            return {
                ...state,
                item_loading: false,
                item: action.payload.item,
                groups: action.payload.groups
            };
        case FETCH_ITEM_FAILURE:
            return {...state, item_loading: false, item_error: true};
        case SAVE_ITEM_REQUEST:
            return {...state, save_success: false, save_error: false};
        case SAVE_ITEM_SUCCESS:
            return {...state, save_success: true};
        case SAVE_ITEM_FAILURE:
            return {...state, save_error: true};
        case CREATE_NEW_ITEM_REQUEST:
            return {...state, new_item_loading: true, new_item_error: false};
        case CREATE_NEW_ITEM_SUCCESS:
            return {...state, new_item_loading: false, new_item_created: action.payload.item.id};
        case CREATE_NEW_ITEM_FAILURE:
            return {...state, new_item_loading: false, new_item_error: true};
        default:
            return state;
    }
}

export default reducer;

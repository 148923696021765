import axios from "axios";
import {config} from "../config";
import {removeUser} from "../redux";
import store from "../redux/store";

export const METHODE = {POST: 'POST', GET: 'GET'};

export const sendRequest = (methode, URLPath, data) => {
    if (methode === METHODE.POST) {
        return postRequest(URLPath, data);
    } else {
        return getRequest(URLPath);
    }
};


const postRequest = (URLPath, data) => new Promise((resolve, reject) => {
    axios.post(config.url.API_URL + URLPath, data, {withCredentials: true})
        .then(response => {
            if (response.data.status) {
                resolve(response.data)
            } else {
                if (response.data.err === 401) {
                    store.dispatch(removeUser());
                } else {
                    resolve(response.data)
                }
            }
        })
        .catch(error => {
            console.log(error)
            const errorMsg = error.message;
            console.error(errorMsg);
            reject();
        });
});

const getRequest = URLPath => new Promise((resolve, reject) => {
    axios.get(config.url.API_URL + URLPath, {withCredentials: true})
        .then(response => {
            if (response.data.status) {
                resolve(response.data)
            } else {
                if (response.data.err === 401) {
                    store.dispatch(removeUser());
                } else {
                    resolve(response.data)
                }
            }
        })
        .catch(error => {
            const errorMsg = error.message;
            console.error(errorMsg);
            reject();
        });
});

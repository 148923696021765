import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchUsers} from "../../../redux";
import {
    CircularProgress,
    FormControl,
    makeStyles,
    Paper,
    Select, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from "@material-ui/core";
import NewUserComponent from "../components/NewUserComponent";
import {SwapVert} from "@material-ui/icons";
import {Pagination} from "@material-ui/lab";
import {isEmpty} from "lodash";
import {getPermissionName} from "../../../utills/Tools";
import {COLORS} from "../../../utills/constants/Colors";

const useStyles = makeStyles(() => ({
    top_container: {
        display: 'flex'
    },
    center_container: {
        justifyContent: 'space-between',
        margin: '20px 0',
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                marginRight: '10px'
            }
        }
    },
    bottom_container: {},
    swipe_sort: {
        cursor: 'pointer'
    },
    search: {
        width: '100%',
        height: '50px',
    },
    table_head: {
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        color: '#fff'
    },
    table_row: {
        cursor: 'pointer'
    },
    pagination: {
        direction: 'ltr'
    }
}));

export default function UsersContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const usersState = useSelector(state => state['users']);
    const [state, setState] = useState({
        sort: 'nickname',
        users: [],
        totalPages: 1,
        page: 1,
        query: ''
    });

    if (state.users.length !== usersState.users.length) {
        setState({
            ...state,
            totalPages: usersState.totalPages,
            users: usersState.users
        });
    }

    useEffect(() => {
        handleFetchUsers();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleFetchUsers = page => dispatch(fetchUsers(state.sort, page ? page : 1));

    if (usersState.new_user_created > 0) window.location.href = '/users/' + usersState.new_user_created;

    if (usersState.loading) return <CircularProgress/>;

    const handleUserClicked = (id, _) => window.location.href = '/users/' + id;

    const handleSortChange = e => {
        const sortValue = e.target.value;
        setState({
            ...state, users: state.users.sort((a, b) => {
                if (a[sortValue] < b[sortValue]) return 1;
                if (a[sortValue] > b[sortValue]) return -1;
                return 0;
            }), sort: sortValue
        });
    }

    const handleOnSwipeSortClicked = () => setState({
        ...state,
        users: Array.from(state.users.reverse())
    });

    const pageChanged = (e, value) => handleFetchUsers(value);

    return <div>
        <div className={classes.top_container}>
            <NewUserComponent/>
        </div>
        <div className={classes.center_container}>
            <div>
                <span>סידור לפי</span>
                <FormControl variant="outlined">
                    <Select
                        native
                        onChange={handleSortChange}
                        value={state.sort}>
                        <option value={'nickname'}>כינוי</option>
                        <option value={'first_name'}>שם פרטי</option>
                        <option value={'last_name'}>שם משפחה</option>
                    </Select>
                </FormControl>
                <SwapVert onClick={handleOnSwipeSortClicked} className={classes.swipe_sort}/>
            </div>
            <Pagination onChange={pageChanged} className={classes.pagination}
                        count={state.totalPages}/>
        </div>
        <div className={classes.bottom_container}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.table_head}>
                        <TableRow>
                            <TableCell align="left">כינוי</TableCell>
                            <TableCell align="left">שם פרטי</TableCell>
                            <TableCell align="left">שם משפחה</TableCell>
                            <TableCell align="right">הרשאה</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isEmpty(state.users)
                            ?
                            <TableRow>
                                <TableCell align="center">אין מתשמשים</TableCell>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                            :
                            state.users.map(user => (
                                <TableRow onClick={handleUserClicked.bind(this, user.id)}
                                          className={classes.table_row} hover key={user.id}>
                                    <TableCell
                                        align="left">{user.nickname}</TableCell>
                                    <TableCell
                                        align="left">{user.first_name}</TableCell>
                                    <TableCell
                                        align="left">{user.last_name}</TableCell>
                                    <TableCell
                                        align="right">{getPermissionName(user.permission)}</TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>
}

import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {formatDateForView, getTotalTime} from "../../../utills/Tools";
import {makeStyles} from "@material-ui/core";
import {useSelector} from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import {COLORS} from "../../../utills/constants/Colors";

const useStyles = makeStyles(() => ({
    report_button: {
        cursor: 'pointer',
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        fontSize: '18px',
        border: '1px solid ' + COLORS.NAVIGATOR_BACKGROUND_COLOR,
        borderRadius: '4px',
        padding: '5px 10px',
        marginBottom: '20px'
    },
    reports_button: {
        cursor: 'pointer',
        backgroundColor: COLORS.NAV_BAR_BACKGROUND_COLOR,
        fontSize: '18px',
        border: '1px solid ' + COLORS.NAVIGATOR_BACKGROUND_COLOR,
        borderRadius: '4px',
        padding: '5px 10px',
    }
}));

export default function ExportReportToExcel(props) {
    const classes = useStyles();
    const report = props.report;
    const reports = props.reports;
    const itemsState = useSelector(state => state['engineeringItems']);

    if (!reports && !report) return <div></div>;

    const createRowsByData = i_items => {
        let html = '';
        itemsState.groups.forEach(group => {
            const items = [];
            i_items.forEach(item => item['group_id'] === group.id && items.push(item));
            if (items.length > 0) {
                html = html.concat('<tr><td align="right">' + (group.number ? group.number : '') + '</td><td align="center"><b>' + group.name + '</b></td><td></td><td></td></tr>');
                items.forEach(item => {
                    html = html.concat('<tr><td align="right">' + item.number + '</td><td>' + item.description + '</td><td>' + item.unit + '</td><td>' + item.count + '</td></tr>');
                });
                html = html.concat('<tr><td colSpan="4" height="20" bgcolor="#C5D9F1"></td></tr>');
            }
        });
        return html;
    };

    const getEmployees = employees => {
        let html = '';
        employees.forEach(employee => html = html.concat(employee.name + '<br/>'))
        return html;
    }
    if (reports && reports.length > 0 && reports[0].reports && reports[0].reports.length > 0) {
        return <div>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-xls"
                filename="טופס דיווח עבודות יומיות בהנדסה"
                sheet="כתב כמויות"
                className={classes.reports_button}
                buttonText="הורד כ Excel"/>
            <table id="table-to-xls" cellSpacing="0" border='1' style={{display: "none"}}>
                {reports[0].reports.map(i_report =>
                    <>
                        <colgroup width="172"></colgroup>
                        <colgroup width="588"></colgroup>
                        <colgroup width="109"></colgroup>
                        <colgroup width="110"></colgroup>
                        <tbody>
                        <tr>
                            <td colSpan="4" align="center" bgcolor="#FFFF00">
                                <b>טופס דיווח עבודות יומיות בהנדסה</b>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="4" align="center" bgcolor="#E4DFEC">תיאור
                                העבודה-בקצרה
                            </td>
                            <td rowSpan="4" bgcolor="#E4DFEC">{i_report.description}</td>
                            <td bgcolor="#D8E4BC">תאריך</td>
                            <td bgcolor="#D8E4BC"><b>{formatDateForView(i_report.date)}</b></td>
                        </tr>
                        <tr>
                            <td bgcolor="#E6B8B7">מספר פנייה</td>
                            <td bgcolor="#E6B8B7"><b>{i_report.number}</b></td>
                        </tr>
                        <tr>
                            <td bgcolor="#B7DEE8">זמן ביצוע</td>
                            <td bgcolor="#B7DEE8">
                                <b>{i_report.from_hour} - {i_report.to_hour} ({getTotalTime(i_report.from_hour, i_report.to_hour)} שעות)</b>
                            </td>
                        </tr>
                        <tr>
                            <td bgcolor="#FFF2CC">שמות העובדים</td>
                            <td bgcolor="#FFF2CC">
                                <b>{ReactHtmlParser(getEmployees(i_report.employees))}</b></td>
                        </tr>
                        <tr>
                            <td align="center"><b>מק"ט מחירון</b></td>
                            <td align="center"><b>תיאור</b></td>
                            <td align="center"><b>יחידת מידה</b></td>
                            <td align="center"><b>כמות</b></td>
                        </tr>
                        {ReactHtmlParser(createRowsByData(i_report.items))}
                        <tr>
                            <td colSpan="4" height="20"></td>
                        </tr>
                        <tr>
                            <td colSpan="4" align="center"><b>במידה וקיימים פריטים נוספים ועבודות
                                נוספות
                                שבוצעו
                                יש לרשום למטה מה בוצע</b></td>
                        </tr>
                        <tr>
                            <td colSpan="4">{i_report.details}</td>
                        </tr>
                        <tr>
                            <td height="200" colSpan="4"></td>
                        </tr>
                        </tbody>
                    </>
                )}
            </table>
        </div>
    }

    return <div>
        <ReactHTMLTableToExcel
            id="test-table-xls-button"
            table="table-to-xls"
            filename="טופס דיווח עבודות יומיות בהנדסה"
            sheet="כתב כמויות"
            className={classes.report_button}
            buttonText="הורד כ Excel"/>
        <table id="table-to-xls" cellSpacing="0" border='1' style={{display: "none"}}>
            <colgroup width="172"></colgroup>
            <colgroup width="588"></colgroup>
            <colgroup width="109"></colgroup>
            <colgroup width="110"></colgroup>
            <tbody>
            <tr>
                <td colSpan="4" align="center" bgcolor="#FFFF00">
                    <b>טופס דיווח עבודות יומיות בהנדסה</b>
                </td>
            </tr>
            <tr>
                <td rowSpan="4" align="center" bgcolor="#E4DFEC">תיאור
                    העבודה-בקצרה
                </td>
                <td rowSpan="4" bgcolor="#E4DFEC">{report.description}</td>
                <td bgcolor="#D8E4BC">תאריך</td>
                <td bgcolor="#D8E4BC"><b>{formatDateForView(report.date)}</b></td>
            </tr>
            <tr>
                <td bgcolor="#E6B8B7">מספר פנייה</td>
                <td bgcolor="#E6B8B7"><b>{report.number}</b></td>
            </tr>
            <tr>
                <td bgcolor="#B7DEE8">זמן ביצוע</td>
                <td bgcolor="#B7DEE8">
                    <b>{report.from_hour} - {report.to_hour} ({getTotalTime(report.from_hour, report.to_hour)} שעות)</b>
                </td>
            </tr>
            <tr>
                <td bgcolor="#FFF2CC">שמות העובדים</td>
                <td bgcolor="#FFF2CC"><b>{ReactHtmlParser(getEmployees(report.employees))}</b></td>
            </tr>
            <tr>
                <td align="center"><b>מק"ט מחירון</b></td>
                <td align="center"><b>תיאור</b></td>
                <td align="center"><b>יחידת מידה</b></td>
                <td align="center"><b>כמות</b></td>
            </tr>
            {ReactHtmlParser(createRowsByData(report.items))}
            <tr>
                <td colSpan="4" height="20"></td>
            </tr>
            <tr>
                <td colSpan="4" align="center"><b>במידה וקיימים פריטים נוספים ועבודות נוספות שבוצעו
                    יש לרשום למטה מה בוצע</b></td>
            </tr>
            <tr>
                <td colSpan="4">{report.details}</td>
            </tr>
            </tbody>
        </table>
    </div>
}

import {
    FETCH_ITEMS_REQUEST,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEM_REQUEST,
    FETCH_ITEM_SUCCESS,
    FETCH_ITEM_FAILURE,
    SAVE_ITEM_REQUEST,
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,
    CREATE_NEW_ITEM_REQUEST,
    CREATE_NEW_ITEM_SUCCESS,
    CREATE_NEW_ITEM_FAILURE
} from "./type";

import {isEmpty} from 'lodash';

import {sendRequest, METHODE} from '../../../../utills/HTTPRequests';


const fetchItemsRequest = () => {
    return {
        type: FETCH_ITEMS_REQUEST
    }
}

const fetchItemsSuccess = data => {
    return {
        type: FETCH_ITEMS_SUCCESS,
        payload: {items: data.items, groups: data.groups, totalPages: data.totalPages}
    }
}

const fetchItemsFailure = () => {
    return {
        type: FETCH_ITEMS_FAILURE
    }
}

const fetchItemRequest = () => {
    return {
        type: FETCH_ITEM_REQUEST
    }
}

const fetchItemSuccess = data => {
    return {
        type: FETCH_ITEM_SUCCESS,
        payload: {item: data.item, groups: data.groups}
    }
}

const fetchItemFailure = () => {
    return {
        type: FETCH_ITEM_FAILURE
    }
}

const saveItemRequest = () => {
    return {
        type: SAVE_ITEM_REQUEST
    }
}

const saveItemSuccess = () => {
    return {
        type: SAVE_ITEM_SUCCESS
    }
}

const saveItemFailure = () => {
    return {
        type: SAVE_ITEM_FAILURE
    }
}

const createNewItemRequest = () => {
    return {
        type: CREATE_NEW_ITEM_REQUEST
    }
}

const createNewItemSuccess = item => {
    return {
        type: CREATE_NEW_ITEM_SUCCESS,
        payload: {item: item}
    }
}

const createNewItemFailure = () => {
    return {
        type: CREATE_NEW_ITEM_FAILURE
    }
}

export const fetchItems = (sort = 'id', page, query = '') => {
    return (dispatch) => {
        dispatch(fetchItemsRequest());
        sendRequest(METHODE.GET, '/engineering/items/' + sort + '/' + page + '/' + query, null)
            .then(data => {
                if (data.status && !isEmpty(data.message)) {
                    dispatch(fetchItemsSuccess(data.message));
                } else {
                    dispatch(fetchItemsFailure());
                }
            })
            .catch(() => dispatch(fetchItemsFailure()));
    };
}

export const fetchItem = id => {
    return (dispatch) => {
        dispatch(fetchItemRequest());
        sendRequest(METHODE.GET, '/engineering/item/' + id, null)
            .then(data => {
                if (data.status && !isEmpty(data.message)) {
                    dispatch(fetchItemSuccess(data.message));
                } else {
                    dispatch(fetchItemFailure());
                }
            })
            .catch(() => dispatch(fetchItemFailure()));
    };
}

export const saveItem = item => {
    return (dispatch) => {
        dispatch(saveItemRequest());
        sendRequest(METHODE.POST, '/engineering/item', item)
            .then((data) => {
                if (data.status) {
                    dispatch(saveItemSuccess());
                } else {
                    dispatch(saveItemFailure());
                }
            })
            .catch(() => dispatch(saveItemFailure()));
    };
}

export const createNewItem = () => {
    return (dispatch) => {
        dispatch(createNewItemRequest());
        sendRequest(METHODE.POST, '/engineering/item/new', null)
            .then((data) => {
                if (data && data.status && !isEmpty(data.message)) {
                    dispatch(createNewItemSuccess(data.message));
                } else {
                    dispatch(createNewItemFailure());
                }
            })
            .catch(() => dispatch(createNewItemFailure()));
    };
}
